const RX_HYPHENATE = /\B([A-Z])/g

// Converts PascalCase or camelCase to kebab-case
export const kebabCase = str => {
  return str.replace(RX_HYPHENATE, '-$1').toLowerCase()
}

// Parse a fully qualified version from a string
export const parseVersion = version => {
  const matches = version.match(/([0-9]+\.[0-9]+\.[0-9]+)/)
  const matchesCount = matches.length
  return matchesCount > 0 ? matches[matchesCount - 1] : ''
}

// Parse a fully qualified version from a string (including alpha/beta/etc
export const parseFullVersion = version => {
  const matches = version.match(/([0-9]+\.[0-9]+\.[0-9]+(?:-[a-z]+[.-]?[0-9]+))/)
  const matchesCount = matches.length
  return matchesCount > 0 ? matches[matchesCount - 1] : ''
}

// Remove any HTML tags, but leave entities alone
const stripHTML = (str = '') => str.replace(/[#\[\]]+/g,'')
const stripHTMLSpace = (str = '') => str.replace(/[#\[\]]+ /g,'')
const stripData = (str = '') => str.replace(/[v][\d.]+/g,'')
// Remove any double quotes from a string
const stripQuotes = (str = '') => str.replace(/[^\w\-]+/g,'')
const replaceDash = (str = '') => str.replace(/ /g,'-').replace(/[.]/g,'')
export const parseUrl = value => {
  const anchor = document.createElement('a')
  anchor.href = value

  // We need to add the anchor to the document to make sure the
  // `pathname` is correctly detected in any browser
  document.body.appendChild(anchor)

  const result = ['hash', 'host', 'hostname', 'pathname', 'port', 'protocol', 'search'].reduce(
    (result, prop) => {
      result[prop] = anchor[prop] || null
      return result
    },
    {}
  )

  // Make sure to remove the anchor from document as soon as possible
  document.body.removeChild(anchor)

  // Normalize port
  if (!result.port && result.protocol) {
    if (result.protocol === 'https:') {
      result.port = '443'
    }
    if (result.protocol === 'http:') {
      result.port = '80'
    }
  }

  // Return early for browsers that resolved a non-existing `hostname` correctly
  if (result.hostname) {
    return result
  }

  // Handle relative URL's
  if (value.charAt(0) === '/') {
    return parseUrl(window.location.origin + value)
  }

  // Handle all other URL's
  let baseUrl = window.location.href
  baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'))

  return parseUrl(`${baseUrl}/${value}`)
}

export const relativeUrl = url => {
  const { pathname, hash } = parseUrl(url)
  if (!pathname) {
    return url
  }

  return pathname + (hash || '')
}

// Process an HTML README and create a page TOC array
// IDs are the only attribute on auto generated heading tags,
// so we take advantage of that when using our RegExpr matches
// Note: IDs may not have quotes when the README's are parsed in production mode !?!?
// Expected format: <h(1|2|3) id="?id-string"?>heading content</h(1|2|3)>
// Also grabs meta data if available to generate auto headings
export const makeTOC = (readme,sub = []) => {
  if (!readme) {
    return {}
  }

  let top = ''
  let title = ''
  const toc = []
  let parentIdx = 0

  // Get the first <h1> tag with ID
  // const h1 = readme.match(/<h1 id=([^> ]+)[^>]*>(.+?)<\/h1>/) || []
  // if (h1) {
  //   top = `#${stripQuotes(h1[1])}`
  //   title = stripHTML(h1[2])
  // }

  // Get all the <h2> and <h3> headings with ID's
  //const headings = readme.match(/<h([3]) id=[^> ]+[^>]*>.+?<\/h\1>/g) || []

  // Process the <h2> and <h3> headings into a TOC structure
  readme
    // Create a match `[value, tag, id, content]`
    .map((content) => {
      const href = `#${stripQuotes(content)}`
      const label = stripHTML(content)
      let subMenu = []
      let dataFilter 
      dataFilter = sub.filter(b=> b.toString()
                  .toLowerCase()
                  .indexOf(label.toLowerCase()) >= 0)
      if(dataFilter.length > 0){
        for(var i = 0 ; i < dataFilter.length; i++){
          let cutText =  stripHTML(dataFilter[i])
          cutText = stripData(cutText)
          let subHref = stripHTMLSpace(dataFilter[i])
          subHref = replaceDash(subHref).toLowerCase()
          subMenu.push({href:`#${subHref}`,label:cutText})
        }
      }
      toc.push({ href, label , subMenu })
      parentIdx = toc.length - 1
      
    })
  return { title, top, toc }
}

export const importAll = r => {
  const obj = {}

  r.keys()
    .map(r)
    .map(m => m.meta || m)
    .map(m => ({
      slug:
        typeof m.slug === 'undefined' ? (m.title || '').replace(' ', '-').toLowerCase() : m.slug,
      ...m
    }))
    .sort((a, b) => {
      if (a.slug < b.slug) return -1
      else if (a.slug > b.slug) return 1
      return 0
    })
    .forEach(m => {
      if (m.components) {
        // Normalize `meta.components` to array of objects form
        m.components = m.components.map(c => (typeof c === 'string' ? { component: c } : c))
      }
      obj[m.slug] = m
    })

  return obj
}

// Smooth Scroll handler methods
const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

export const scrollTo = (scroller, to, duration, cb) => {
  const start = scroller.scrollTop
  const change = to - start
  const increment = 20
  let currentTime = 0
  const animateScroll = function() {
    currentTime += increment
    const val = easeInOutQuad(currentTime, start, change, duration)
    scroller.scrollTop = Math.round(val)
    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    } else if (cb && typeof cb === 'function') {
      cb()
    }
  }
  animateScroll()
}

// Return an element's offset wrt document element
// https://j11y.io/jquery/#v=git&fn=jQuery.fn.offset
export const offsetTop = el => {
  if (!el.getClientRects().length) {
    return 0
  }
  const bcr = el.getBoundingClientRect()
  const win = el.ownerDocument.defaultView
  return bcr.top + win.pageYOffset
}

export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult
  if (removedIndex === null && addedIndex === null) return arr

  const result = [...arr]
  let itemToAdd = payload

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0]
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd)
  }

  return result
}

export const generateItems = (count, creator) => {
  const result = []
  for (let i = 0; i < count; i++) {
    result.push(creator(i))
  }
  return result
}