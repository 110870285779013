<template>
  <div style="background-color: #f5faff">
    <el-row type="flex" justify="center">
      <el-col :md="18" class="mg-x-auto">
        <div
          :class="$route.name != 'Signin' ? '' : 'text-center'"
          style="width: 300px"
          class="mg-x-auto mg-b-3 pd-t-3"
        >
          <router-link
            to="/"
            :class="
              $route.name == 'Signin' || page != ''
                ? 'pd-t-5 d-block pd-x-6 mg-x-auto'
                : ''
            "
          >
            <img src="@/assets/logo.svg" alt="" class="mw-100" />
          </router-link>
        </div>
        <div class="bg-white pd-2 rounded">
          <div
            class="sticky mg-r-4 t-right hidden-md-and-up"
            :class="{ active: page.window > 1269 }"
            v-if="drawer == false"
          >
            <i class="el-icon-s-fold" @click="drawer = !drawer"></i>
          </div>
          <h1 class="mg-t-0">Changelog</h1>
          <el-row>
            <el-col :lg="20" :xs="24" class="pd-x-4 border-right">
              <markdown-it-vue class="md-body th" :content="fileContent" />
            </el-col>
            <el-col :lg="4" class="hidden-md-and-down">
              <div class="sticky" :class="{ active: page.window > 1369 }">
                <h3>Changelog</h3>
                <ul class="pd-l-3">
                  <li
                    v-for="(data, index) in toc.toc"
                    :class="{ isActive: data.href === activeBlock }"
                    :key="index"
                  >
                    <a
                      class="has-text-weight-light"
                      @click="scrollIntoView($event, data.href)"
                      :href="data.href"
                    >
                      {{ data.label }}
                    </a>
                    <ul class="pd-l-4" v-if="data.href === activeBlock">
                      <li
                        v-for="(sub, index) in data.subMenu"
                        :key="index"
                        class="font-16"
                      >
                        <a
                          @click="scrollIntoView($event, sub.href)"
                          :href="sub.href"
                          class="has-text-weight-light text-grey-light"
                          >{{ sub.label }}</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
        <div
          class="slideMenu"
          :class="{ Active: drawer }"
          @click="drawer = !drawer"
        >
          <div class="innerMenu">
            <div class="mg-b-4">
              <i class="el-icon-close cr-pointer" @click="drawer = true"></i>
            </div>
            <h4>Changelog</h4>
            <ul class="pd-l-3">
              <li
                v-for="(data, index) in toc.toc"
                :class="{ isActive: data.href === activeBlock }"
                class="mg-b-5"
                :key="index"
              >
                <a
                  class="has-text-weight-light"
                  @click="scrollIntoView($event, data.href)"
                  :href="data.href"
                >
                  {{ data.label }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { offsetTop, scrollTo, makeTOC } from "@/service/util";
import MarkdownItVue from "markdown-it-vue";
import "markdown-it-vue/dist/markdown-it-vue.css";
import txt from "!!raw-loader!../../CHANGELOG.md";
export default {
  name: "changeLog",
  components: {
    MarkdownItVue
  },
  data() {
    return {
      drawer: false,
      maxWidth: window.innerWidth,
      page: { window: 0, top: 0 },
      activeBlock: null,
      toc: [],
      fileContent: txt,
      textFor: txt,
      options: {
        markdownIt: {
          linkify: true
        },
        linkAttributes: {
          attrs: {
            target: "_blank",
            rel: "noopener"
          }
        },
        icons: "font-awesome",
        githubToc: {
          tocFirstLevel: 2,
          tocLastLevel: 3,
          tocClassName: "toc",
          anchorLinkSymbol: "",
          anchorLinkSpace: false,
          anchorClassName: "anchor",
          anchorLinkSymbolClassName: "octicon octicon-link"
        }
      }
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.cutWord();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    elementInViewport(el) {
      var top = el.offsetTop;
      var height = el.offsetHeight;

      while (el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
      }
      this.page.window = window.pageYOffset + window.innerHeight;
      this.page.top = top + height;
      return (
        top >= window.pageYOffset &&
        top + height <= window.pageYOffset + window.innerHeight
      );
    },
    handleScroll() {
      const elementsInViewArray = this.toc.toc.map(number => {
        const el = document.getElementById(number.href.replace("#", ""));
        if (this.elementInViewport(el)) {
          return number.href;
        }
      });
      this.activeBlock = elementsInViewArray.find(number => number);
    },
    cutWord() {
      let txtLength = this.textFor.length;
      let txtData = this.textFor.toString();
      let subData = this.textFor.toString();
      let allData = [];
      let allSub = [];
      for (var i = 0; i < txtLength; i++) {
        let anchor = txtData.match(/[#]+[#] [[]+[\S]+/);
        if (anchor != null) {
          allData.push(anchor[0]);
          var res = txtData.replace(anchor[0], "");
          txtData = res;
        }
      }
      for (var i = 0; i < txtLength; i++) {
        let anchorSub = subData.match(/[#]+[#]+[#]+[ \w.\d]+/);
        if (anchorSub != null) {
          allSub.push(anchorSub[0]);
          var res = subData.replace(anchorSub[0], "");
          subData = res;
        }
      }
      this.toc = makeTOC(allData, allSub);
    },
    scrollIntoView(evt, href) {
      evt.preventDefault();
      evt.stopPropagation();
      const id = (href || "").replace(/#/g, "");
      const $el = document.body.querySelector(`[id="${id}"]`);
      if ($el) {
        const scroller =
          document.scrollingElement ||
          document.documentElement ||
          document.body;
        scrollTo(scroller, offsetTop($el) - 70, 100, () => {
          $el.tabIndex = -1;
        });
      }
    }
  }
};
</script>
<style scoped>
.slideMenu {
  position: fixed;
  top: 0;
  background: rgb(0, 0, 0, 0.8);
  right: -2000px;
  bottom: 0;
  transition: all 0.4s;
  --webkit-transition: all 0.4s;
  width: 100%;
}

.slideMenu.Active {
  right: 0;
}
.innerMenu {
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  left: 45%;
  bottom: 0;
  z-index: 10000;
  padding: 15px 25px;
}

h1 {
  font-size: 36px;
}

.log-lists li {
  list-style: disc;
}

.sticky li a,
.innerMenu li a {
  color: #606060;
  font-size: 24px;
  font-weight: 400;
}
.sticky li {
  width: 100%;
}
.sticky > ul {
  width: 140px;
  list-style-type: none;
  height: calc(100vh - 120px);
  overflow-y: auto;
}

.sticky li.isActive a,
.sticky li.isActive,
.innerMenu li.isActive a,
.innerMenu li.isActive {
  color: #0366d6;
  font-weight: 400;
}
.mg-l-35 {
  margin-left: 35px !important;
}

.sticky.active {
  position: fixed;
  top: 0;
  z-index: 1;
}

.sticky {
  position: relative;
  padding: 0 10px;
  font-size: 20px;
  z-index: 1;
}

@media screen and (min-width: 100px) and (max-width: 768px) {
  .border-right-solid {
    border-right: 0 !important;
  }
  .sticky.active {
    top: 2%;
  }
  .sticky {
    top: 2%;
    width: 32px;
    height: 32px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 1%;
    background: #fff;
    border-radius: 100%;
    -webkit-box-shadow: 1px 1px 10px 1px rgba(99, 99, 99, 1);
    -moz-box-shadow: 1px 1px 10px 1px rgba(99, 99, 99, 1);
    box-shadow: 1px 1px 10px 1px rgba(99, 99, 99, 1);
  }
}
</style>
